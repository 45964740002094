<template>
	<div class="login-wrapper">
		<div class="login-banner">
			<div class="banner-title">资源管理库</div>
		</div>
		<div class="login-content">
			<div class="login-content-title">账号登录</div>
			<el-form :model="ruleForm" :rules="rules" labelPosition="top" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="用户名" prop="name">
					<el-input v-model="ruleForm.name" @keyup.enter.native="submitForm('ruleForm')"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input v-model="ruleForm.password" type="password" show-password @keyup.enter.native="submitForm('ruleForm')"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">登 录</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		created() {
			if (localStorage.getItem('username')) {
				this.$router.push('/admin/index');
			}
		},
		data() {
			return {
				ruleForm: {
					name: '',
					password: ''
				},
				rules: {
					name: [
					    {required: true, message: '请输入用户名', trigger: 'blur'},
                        { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
                    ],
					password: [
					    {required: true, message: '请输入密码', trigger: 'blur'},
                        { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' }
                    ],
				}
			}
		},
		methods: {
			submitForm(formName) {
				const _this = this;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let formData = new FormData();
						formData.append('admin_account', this.ruleForm.name);
						formData.append('admin_password', this.ruleForm.password);
						this.$http.axiosPost(this.$api.matchAdminLogin, formData, (res) => {
							if (res.code === 200) {
								if (res.data.role === 1) {
									localStorage.setItem('token', res.data.token);
									localStorage.setItem('username', res.data.username);
									localStorage.setItem('role', res.data.role);
									this.$message({
										message: res.msg,
										duration: 500,
										type: 'success',
										onClose: function() {
											_this.$router.push('/admin/index');
										}
									});
								} else if (res.data.role === 2) {
									localStorage.setItem('token', res.data.token);
									localStorage.setItem('username', res.data.username);
									localStorage.setItem('role', res.data.role);
									localStorage.setItem('school_id', res.data.userInfo.s_id);
									this.$message({
										message: res.msg,
										duration: 500,
										type: 'success',
										onClose: function() {
											// _this.$router.push('/admin/educationManage/index');
											_this.$router.push('/admin/index');
										}
									});
								}
							} else {
								this.$message({
									message: res.msg,
									duration: 1000,
									type: 'error'
								});
							}
						}, (err) => {
							console.log(err);
						})
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss">
	.login-wrapper {
		min-width: 1200px;
		height: 100vh;
		background: #fff;
		display: flex;

		.login-banner {
			width: 25%;
            color: #f0f7ff;
            background-color: #409EFF;
			background-image: url(../../assets/images/login-bg.png);
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-position: left bottom;
			position: relative;

			.banner-title {
				font-size: 36px;
				margin-top: 50px;
				font-weight: bold;
				position: absolute;
				top: 30%;
				width: 100%;
				text-align: center;
			}
		}

		.login-content {
			flex: 1;
			width: 1%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.login-content-title {
				font-size: 24px;
				font-weight: 500;
				margin-bottom: 60px;
				color: #000;
			}

			.el-form-item__label {
				line-height: 1.5;
			}

			.demo-ruleForm {
				width: 416px;

				.el-button {
					width: 100%;

					span {
						font-size: 16px;
						font-weight: 700;
					}
				}
			}
		}
	}
</style>
